import config from '../config';
import { Illustration } from '@microrealestate/commonui/components';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';

export const EmptyIllustration = ({ label }) => {
  const { t } = useTranslation();
  return (
    <Illustration
      src={`${config.BASE_PATH}/undraw_Empty_re_opql.svg`}
      alt="empty"
      label={label || t('No data found')}
    />
  );
};

export const LocationIllustration = ({ width, height }) => (
  <Illustration
    src={`${config.BASE_PATH}/undraw_Location_tracking.svg`}
    alt="no location found"
    width={width}
    height={height}
  />
);

export const BlankDocumentIllustration = () => (
  <Illustration
    src={`${config.BASE_PATH}/undraw_add_document_re_mbjx.svg`}
    alt="blank document"
  />
);

export const TermsDocumentIllustration = () => (
  //TODO: fill the alt attribute
  <Illustration src={`${config.BASE_PATH}/undraw_Terms_re_6ak4.svg`} alt="terms document illustration" />
);

export const WelcomeIllustration = ({ height = '100%' }) => (
  <Illustration
    src={`${config.BASE_PATH}/undraw_apartment_rent_o0ut.svg`}
    height={height}
    alt="welcome"
  />
);

export const CelebrationIllustration = ({ label, height = '100%' }) => (
  <Illustration
    src={`${config.BASE_PATH}/undraw_Celebration_re_kc9k.svg`}
    height={height}
    label={label}
    alt="celebration"
  />
);

export const LogoAuthIllustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/logosuperkos.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const LogoIconIllustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/logosuperkos.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const LogoNavIllustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/logonavbar.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const LogoNav2Illustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/logonavbar2.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const LogoNavBlackIllustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/logonavbar_black.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const Error404Illustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/404error.svg`}
    alt="logo"
    width={width}
    height={height}
  />
);

export const PDFDocumentIllustration = ({ height, width }) => (
  <Illustration
    src={`${config.BASE_PATH}/pdf_document.svg`}
    alt="pdfIcon"
    width={width}
    height={height}
  />
);