import { Grid } from '@material-ui/core';
import { TextField } from './TextField';
import useTranslation from 'next-translate/useTranslation';
import { PhoneField } from './PhoneField';

export function ContactField({
  contactName,
  emailName,
  phoneName,
  disabled,
}) {
  const { t } = useTranslation('common');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          label={t('Name')+" *"}
          name={contactName || 'Name'}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          label={t('Email')}
          name={emailName || 'email'}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PhoneField
          label={t('Phone / WA')+" *"}
          name={phoneName || 'phone'}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
