import React from 'react'
import ToggleMenu from '../ToggleMenu';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import { NotificationsNoneOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { Badge, SvgIcon, makeStyles } from '@material-ui/core';

function CustomIdleNotification(props) {
  return (
    <Badge overlap="rectangular" badgeContent={2} color="error">
      <NotificationsNoneOutlined />
    </Badge>
  )
}

const OrganizationNotifications = observer(() => {

  const options = [
    {
      _id: 1,
      tenantName: "ali"
    },
    {
      _id: 2,
      tenantName: "akbar"
    },
  ]
  return (
    <>
      <ToggleMenu
        startIcon={
          <CustomIdleNotification />
        }
        options={options}
        // selectedIds={value ? [value] : []}
        onChange={'*'}
      />
    </>
  )
});

export default OrganizationNotifications