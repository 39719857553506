import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';

export default class Subscription {
  constructor() {
    this.data = {};

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      create: flow,
    });
  }

  *fetchData() {
    try {
      const response = yield api.get(`/subscriptions`);
      this.data = response.data.subscriptions
      return { status: 200, data: response.data.subscriptions };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(data) {
    try {
      const response = yield api.post('/subscriptions', data);
      // const createdSubscription = response.data;
      // this.items = updateItems(createdSubscription, this.items);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}