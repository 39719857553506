import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useField } from "formik";
import PhoneInput from 'react-phone-number-input'
// import PhoneInput from 'react-phone-number-input/input'
import React, { useCallback, useState } from 'react';

const CustomInputComponent = React.forwardRef(({ value, onChange, ...props }, ref) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <Input
      {...props}
      inputRef={ref}
      fullWidth
      value={value}
      onChange={handleChange}
    />
    // <TextField
    //   {...props}
    //   inputRef={ref}
    //   fullWidth
    //   value={value}
    //   onChange={handleChange}
    // />
  );
});

export function PhoneField({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const customStyles = {
    width: '100%',
    padding: '2px 4px',
    // border: 'none',
    marginTop: '18px',
  };

  return (
    <FormControl margin="normal" fullWidth>
      {label ? (
        <InputLabel htmlFor={props.name} error={hasError} shrink>
          {label}
        </InputLabel>
      ) : null}

      <PhoneInput
        international
        defaultCountry='ID'
        style={customStyles}
        {...props}
        {...field}
        value={field.value}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        inputComponent={CustomInputComponent}
      />

      {hasError && (
        <FormHelperText error={hasError}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}