import { action, computed, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';
import { updateItems } from './utils';
import { makePersistable } from 'mobx-persist-store';
import { FlipTwoTone } from '@material-ui/icons';


export default class Property {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '' };
    this.items = [];
    this.filtersbyRole = [];

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      filteredItems: computed,
      filteredItemsByRole: computed,
      setSelected: action,
      setItems: action,
      setFilters: action,
      setFiltersByRole: action,
      fetch: flow,
      fetchOne: flow,
      fetchFilterRoleOne: flow,
      create: flow,
      update: flow,
      delete: flow,
      deletePropertyLogo: flow,
    });
    makePersistable(this, {
      name: 'Property', properties: ['selected', 'items', 'filters'],
      storage: typeof window !== "undefined" ? window.localStorage : undefined,
      expireIn: 86400000, // One day in milliseconds
      removeOnExpiration: true,
    });

  }

  get filteredItems() {
    let filteredItems = this.items;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItems = filteredItems.filter(
        ({ name }) =>
          name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
          -1
      );
    }
    return filteredItems;
  }

  get filteredItemsByRole() {
    let filteredItemsByRole = this.filtersbyRole;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItemsByRole = filteredItemsByRole.filter(
        ({ name }) =>
          name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
          -1
      );
    }
    return filteredItemsByRole;
  }

  setSelected = (property) => (this.selected = property);

  setFilters = ({ searchText = '' }) =>
    (this.filters = { searchText });

  setItems = (properties) => {
    this.items = updateItems(properties, this.items);
  };

  setFiltersByRole = (properties) => {
    this.filtersByRole = properties;
  };

  *fetch() {
    try {
      const response = yield api.get('/properties');

      this.items = response.data;
      if (this.selected?._id) {
        this.setSelected(
          this.items.find((item) => item._id === this.selected._id) || {}
        );
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(propertyId) {
    try {
      const response = yield api.get(`/properties/${propertyId}`);
      const updatedProperty = response.data;
      this.items = updateItems(updatedProperty, this.items);
      if (this.selected?._id === updatedProperty._id) {
        this.selected = updatedProperty;
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchFilterRoleOne(propertyId) {
    try {
      const response = yield api.get(`/properties/${propertyId}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(property) {
    try {
      const response = yield api.post('/properties', property);
      const createdProperty = response.data;
      this.items = updateItems(createdProperty, this.items);

      return { status: 200, data: createdProperty };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(property) {
    try {
      const response = yield api.patch(
        `/properties/${property._id}`,
        property
      );
      const updatedProperty = response.data;
      this.items = updateItems(updatedProperty, this.items);
      if (this.selected?._id === updatedProperty._id) {
        this.setSelected(updatedProperty);
      }
      return { status: 200, data: updatedProperty };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield api.delete(`/properties/${ids.join(',')}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deletePropertyLogo(ids, documentCategory) {
    try {
      yield api.delete(`/documents/property-logo/${ids.join(',')}?documentCategory=${documentCategory}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
