import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

export function SelectField({ label, values = [], defaultValue, disabled, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const hasError = !!(meta.touched && meta.error);

  if (defaultValue !== undefined && !meta.touched && field.value !== defaultValue) {
    helpers.setValue(defaultValue);
  }

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor={props.name} error={hasError}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled || isSubmitting}
        error={hasError}
        {...field}
        {...props}
        value={field.value || ''}
      >
        {values.map(({ id, value, label, disabled: disabledMenu }) => (
          <MenuItem key={id} value={value} disabled={disabledMenu}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {hasError && (
        <FormHelperText error={hasError}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}



