import React from 'react'
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString={true}
      prefix="Rp "
    />
  );
}
// const Adorment

export function CustomPriceField({ label, disabled, ...props }) {
  const [field, meta] = useField(props);
  const { isSubmitting } = useFormikContext();
  const hasError = !!(meta.touched && meta.error);

  return (
    <FormControl margin="normal" fullWidth>
      {/* Use TextField */}
      {/* <TextField
        error={hasError}
        disabled={disabled || isSubmitting}
        fullWidth
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        label={label}
        {...props}
        {...field}
      /> */}

      {/* Use Input */}
      {label ? (
        <InputLabel htmlFor={props.name} error={hasError} shrink>
          {label}
        </InputLabel>
      ) : null}
      <Input
        error={hasError}
        disabled={disabled || isSubmitting}
        fullWidth
        inputComponent={NumberFormatCustom}
        {...props}
        {...field}
      />
      {hasError && (
        <FormHelperText error={hasError}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  )
}

// export default TestPriceField