import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class DocumentExpense {
  constructor() {
    this.selected = {};
    this.items = [];

    makeObservable(this, {
      selected: observable,
      setSelected: action,
      items: observable,
      create: flow,
      delete: flow,
    });
  }

  setSelected = (documentExpense) => (this.selected = documentExpense);

  *create(documentExpense) {
    try {
      const response = yield api.post('/documents/expense', documentExpense);
      const createdDocument = response.data;
      this.items = updateItems(createdDocument, this.items);

      return { status: 200, data: createdDocument };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield api.delete(`/documents/expense/${ids.join(',')}`);
      this.items = this.items.filter((documentExpense) => !ids.includes(documentExpense._id));
      if (ids.includes(this.selected?._id)) {
        this.selected = null;
      }
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
