import { drawerWidth } from '../styles.js';
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    position: 'fixed',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    border: 0,
    zIndex: theme.zIndex.appBar + 2,
    backgroundColor: [theme.palette.primary.main, '!important']
  },
  moreMenuMobile: {
    backgroundColor: theme.palette.common.black,
    zIndex: theme.zIndex.appBar + 1,
  },
  drawerClose: {
    position: 'fixed',
    width: theme.spacing(7) + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    border: 0,
    zIndex: theme.zIndex.appBar + 2,
    backgroundColor: [theme.palette.primary.dark, '!important']
  },
  list: {
    margin: 'auto 0',
    height: '100%',
    color: theme.palette.navbar.background,
  },
  itemSelected: {
    color: [theme.palette.info.contrastText, '!important'],
    backgroundColor: [theme.palette.primary.dark, '!important'],
  },
  mobileItemSelected: {
    color: [theme.palette.primary.main, '!important'],
    backgroundColor: ['none', '!important'],
  },
  item: {
    color: theme.palette.navbar.background,
  },
  itemTextOpen: {
    opacity: 100,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  itemTextClose: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoOpen: {
    marginRight: '0px'
  }
}));
