import React, { useState, useCallback } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Button,
  Grid,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useField, useFormikContext } from 'formik';
import useTranslation from 'next-translate/useTranslation';

export function GeneratePassword({
  label,
  disabled,
  showHidePassword = true,
  ...props
}) {
  const [displayPassword, showPassword] = useState(false);
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const hasError = !!(meta.touched && meta.error);
  const { t } = useTranslation('common');

  const handleClickShowPassword = useCallback(() => {
    showPassword((displayPassword) => !displayPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  const generatePassword = useCallback(() => {
    const newPassword = Math.random().toString(36).slice(-8);
    helpers.setValue(newPassword);
  }, [helpers]);

  return (
    <FormControl margin="normal" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={8}>
          {label ? (
            <InputLabel htmlFor={props.name} error={hasError} shrink>
              {label}
            </InputLabel>
          ) : null}
          <Input
            error={hasError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {displayPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            disabled={disabled || isSubmitting}
            fullWidth
            {...props}
            {...field}
            autoComplete="current-password"
            type={displayPassword ? 'text' : 'password'}
          />
          {hasError && (
            <FormHelperText error={hasError}>{meta.error}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={4}>
          <Button size="small" variant="contained" onClick={generatePassword} disabled={disabled || isSubmitting}>
            {t('Generate Password')}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}
