import { action, computed, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';
import { makePersistable } from 'mobx-persist-store';

export default class Organization {
  constructor() {
    this.selected = undefined;
    this.items = [];

    makeObservable(this, {
      selected: observable,
      items: observable,
      setSelected: action,
      setItems: action,
      // canSendEmails: computed,
      // canUploadDocumentsInCloud: computed,
      fetch: flow,
      fetchAndSelect: flow,
      create: flow,
      update: flow,
      validation: flow,
      validationXendit:flow,
    });

    makePersistable(this, {
      name: 'Organization', properties: ['selected', 'items'],
      storage: typeof window !== "undefined" ? window.localStorage : undefined,
      expireIn: 86400000, // One day in milliseconds
      removeOnExpiration: true,
    });

  }

  setSelected = (org, user) => {
    this.selected = org;
    user.setRole(
      this.selected.members.find(({ email }) => email === user.email).role
    );
  };

  setItems = (organizations = []) => {
    this.items = organizations;
  };


  // get canSendEmails() {
  //   return (
  //     !!this.selected?.thirdParties?.gmail?.selected ||
  //     this.selected?.thirdParties?.mailgun?.selected
  //   );
  // }

  *fetch() {
    try {
      const response = yield api.get('/realms');
      this.setItems(response.data);
      return 200;
    } catch (error) {
      console.error(error);
      return error?.response?.status;
    }
  }

  *fetchAndSelect() {
    try {
      const response = yield api.get('/realms');
      this.setItems(response.data);
      if (this.selected?._id) {
        this.setSelected(
          this.items.find((item) => item._id === this.selected._id) || {}
        );
      }
    } catch (error) {
      console.error(error);
      return error?.response?.status;
    }
  }

  *create(organization) {
    try {
      const response = yield api.post('/realms', organization);
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: error?.response?.status };
    }
  }

  *update(organization) {
    try {
      const response = yield api.patch(
        `/realms/${organization._id}`,
        organization
      );
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: error?.response?.status };
    }
  }
  *validation(organization) {
    try {
      const response = yield api.post('/realms/midtrans', organization);
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: error?.response?.status };
    }
  }

  *validationXendit(organization) {
    try {
      const response = yield api.post('/realms/xendit', organization);
      console.log(response)
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: error?.response?.status };
    }
  }
}
