import { action, makeObservable, observable } from 'mobx';
import { setAccessToken, setOrganizationId } from '../utils/fetch';

import Expense from './Expense';
import Report from './Report';
import Dashboard from './Dashboard';
import Document from './Document';
import DocumentExpense from './DocumentExpense';
import DocumentLandingPage from './DocumentLandingPage';
import Lease from './Lease';
import moment from 'moment';
import Organization from './Organization';
import Property from './Property';
import Room from './Room';
import Rent from './Rent';
import Template from './Template';
import Tenant from './Tenant';
import User from './User';
import Invoice from './Invoice';
import Payment from './Payment';
import Subscription from './Subscription';

export default class Store {
  constructor() {
    this.toastMessages = [];
    this.user = new User();
    this.organization = new Organization();
    this.lease = new Lease();
    this.rent = new Rent();
    this.tenant = new Tenant();
    this.property = new Property();
    this.room = new Room();
    this.template = new Template();
    this.document = new Document();
    this.documentExpense = new DocumentExpense();
    this.documentLandingPage = new DocumentLandingPage();
    this.dashboard = new Dashboard();
    this.expense = new Expense();
    this.report = new Report();
    this.invoice = new Invoice();
    this.payment = new Payment()
    this.subscription = new Subscription()


    makeObservable(this, {
      toastMessages: observable,
      pushToastMessage: action,
      shiftToastMessage: action,

      user: observable,
      organization: observable,
      lease: observable,
      rent: observable,
      tenant: observable,
      property: observable,
      room: observable,
      template: observable,
      document: observable,
      documentExpense: observable,
      documentLandingPage: observable,
      dashboard: observable,
      expense: observable,
      report: observable,
      invoice: observable,
      payment: observable,
      subscription: observable,
    });
  }


  hydrate(initialData) {
    if (!initialData) {
      return;
    }

    const {
      user = {
        items: [],
      },
      organization = {
        items: [],
      },
      invoice = {
        items: [],
      },
      payment = {
        items: [],
      },
      lease = {
        items: [],
      },
      rent = {
        items: [],
      },
      tenant = {
        items: [],
      },
      property = {
        items: [],
      },
      room = {
        items: [],
      },
      template = {
        items: [],
        fields: [],
      },
      document = {
        items: [],
      },
      documentExpense = {
        items: [],
      },
      documentLandingPage = {
        items: [],
      },
      dashboard = {
        data: {},
      },
      expense = {
        data: {},
      },
      report = {
        data: {},
      },
      subscription = {
        data: {}
      }
    } = initialData;

    this.user.fullName = user.fullName;
    this.user.email = user.email;
    this.user.role = user.role;
    this.user.token = user.token;
    this.user.tokenExpiry = user.tokenExpiry;
    this.user.items = user.items;
    this.user.selected = user.selected;
    setAccessToken(user.token);

    this.organization.items = organization.items;
    this.organization.selected = organization.selected;
    setOrganizationId(organization.selected?._id);

    this.lease.items = lease.items;
    this.lease.selected = lease.selected;

    this.rent.items = rent.items;
    this.rent.selected = rent.selected;
    this.rent.filters = rent.filters;
    if (rent._period) {
      this.rent.setPeriod(moment(rent._period));
    }
    this.rent.countAll = rent.countAll;
    this.rent.countPaid = rent.countPaid;
    this.rent.countPartiallyPaid = rent.countPartiallyPaid;
    this.rent.countNotPaid = rent.countNotPaid;
    this.rent.totalToPay = rent.totalToPay;
    this.rent.totalPaid = rent.totalPaid;
    this.rent.totalNotPaid = rent.totalNotPaid;

    this.tenant.items = tenant.items;
    this.tenant.selected = tenant.selected;
    this.tenant.filters = tenant.filters;

    this.invoice.items = invoice.items;
    this.invoice.selected = invoice.selected;
    this.invoice.filters = invoice.filters;

    this.payment.items = payment.items;
    this.payment.selected = payment.selected;
    this.payment.filters = payment.filters;

    this.property.items = property.items;
    this.property.selected = property.selected;
    this.property.filters = property.filters;

    this.room.items = room.items;
    this.room.selected = room.selected;
    this.room.filters = room.filters;

    this.template.items = template.items;
    this.template.selected = template.selected;
    this.template.fields = template.fields;

    this.document.items = document.items;
    this.document.selected = document.selected;

    this.documentExpense.items = documentExpense.items;
    this.documentExpense.selected = documentExpense.selected;

    this.documentLandingPage.items = documentLandingPage.items;
    this.documentLandingPage.selected = documentLandingPage.selected;

    this.dashboard.data = dashboard.data;

    this.subscription.data = subscription.data;

    this.expense.data = expense.data;
    this.expense.filters = expense.filters;

    this.report.data = report.data;
  }

  pushToastMessage(error) {
    this.toastMessages.push(error);
  }

  shiftToastMessage() {
    return this.toastMessages.shift();
  }
}