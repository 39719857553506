import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';
import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class Payment {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '' };
    this.items = [];

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      setSelected: action,
      setFilters: action,
      fetch: flow,
      midtrans: flow,
      xendit: flow,
      create: flow,
      update: flow,
    });
  }

  get filteredItems() {
    let filteredItems = this.items;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText.toLowerCase().replace(regExp, '');
      filteredItems = filteredItems.filter(({ _id, invoiceId }) => {
        let found =
          invoiceId.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !== -1;
        return found;
      });
    }

    return filteredItems;
  }

  setSelected = (payment) => (this.selected = payment);

  setFilters = ({ searchText = '' }) => (this.filters = { searchText });

  *fetch() {
    try {
      const response = yield api.get('/payments');
      this.items = response.data;
      if (this.selected._id) {
        this.setSelected(this.items.find((item) => item._id === this.selected._id) || {});
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *midtrans(invoice) {
    try {
      const response = yield api.post('/payments/midtrans', invoice);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response.status };
    }
  }

  *xendit(invoice) {
    try {
      const response = yield api.post('/payments/xendit', invoice);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response.status };
    }
  }

  *create(payment) {
    try {
      const response = yield api.post('/payments', payment);
      const createdpayment = response.data;
      this.items = updateItems(createdpayment, this.items);

      return { status: 200, data: createdpayment };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(payment) {
    try {
      const response = yield api.patch(`/payments/${payment._id}`, payment);
      const updatedpayment = response.data;
      this.items = updateItems(updatedpayment, this.items);
      if (this.selected?._id === updatedpayment._id) {
        this.setSelected(updatedpayment);
      }

      return { status: 200, data: updatedpayment };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
