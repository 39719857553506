import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class DocumentLandingPage {
  constructor() {
    this.selected = {};
    this.items = [];

    makeObservable(this, {
      selected: observable,
      setSelected: action,
      items: observable,
      fetch: flow,
      fetchProperty: flow,
      fetchImage: flow,
      fetchOne: flow,
      create: flow,
      update: flow,
      delete: flow,
    });
  }

  setSelected = (documentLandingPage) => (this.selected = documentLandingPage);

  *fetch() {
    try {
      const response = yield api.get('/documents/landingPage/all');
      this.items = response.data;
      if (this.selected) {
        this.selected = this.items.find(({ _id }) => this.selected._id === _id);
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchProperty(propertyId) {
    try {
      const response = yield api.get(`/documents/landingPageProperty/${propertyId}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      this.items = [];
      return { status: error?.response?.status};
    }
  }
  
  
  *fetchImage(documentId) {
    try {
      const response = yield api.get(`/documents/landingPageDownload/${documentId}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(documentId) {
    try {
      const response = yield api.get(`/documents/landingPage/${documentId}`);
      const updatedDocument = response.data;
      this.items = updateItems(updatedDocument, this.items);
      if (this.selected?._id === updatedDocument._id) {
        this.selected = updatedDocument;
      }
      return { status: 200, data: updatedDocument };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(documentLandingPage) {
    try {
      const response = yield api.post('/documents/FileLP', documentLandingPage);
      const createdDocument = response.data;
      this.items = updateItems(createdDocument, this.items);

      return { status: 200, data: createdDocument };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(documentLandingPage) {
    try {
      const response = yield api.patch('/documents/FileLP', documentLandingPage);
      const updatedDocument = response.data;
      this.items = updateItems(updatedDocument, this.items);
      if (this.selected?._id === updatedDocument._id) {
        this.selected = updatedDocument;
      }
      return { status: 200, data: updatedDocument };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield api.delete(`/documents/FileLP/${ids.join(',')}`);
      this.items = this.items.filter((documentLandingPage) => !ids.includes(documentLandingPage._id));
      if (ids.includes(this.selected?._id)) {
        this.selected = null;
      }
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
