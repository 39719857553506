import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import moment from 'moment';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';

export default class Expense {
  constructor() {
    this.data = {};
    this.dataRecurring = {};
    this.dataNoRecurring = {};
    this.filters = { year: moment().year(), month: moment().month() + 1, propertyId: "", category: "", roomId: "" };
    this.selected = {};
    this.selectedExpense = [];
    
    makeObservable(this, {
      data: observable,
      dataRecurring: observable,
      dataNoRecurring: observable,
      selectedExpense: observable,
      filters: observable,
      selected: observable,
      filteredRecurring: computed,
      filteredNoRecurring: computed,
      setFilters: action,
      setSelected: action,
      fetchWithoutProperty: flow,
      fetchRecurring: flow,
      fetchNoRecurring: flow,
      deleteRecurring: flow,
      stopRecurring: flow,
      sameById: flow,
      delete: flow,
      create: flow,
      update: flow,
      updateAll: flow,
    });

    reaction(
      () => this.filters, // Hanya memantau perubahan pada filters
      () => {
        this.fetchWithoutProperty();
        this.fetchRecurring();
        this.fetchNoRecurring();
      }
    );
  }

  setSelected = (expense) => (this.selected = expense);
  setSelectedExpense = (expense) => (this.selectedExpense = expense);

  get filteredRecurring() {
    const filteredData = Object.values(this.dataRecurring).filter(expense => {

      if (
        this.filters.propertyId === 'all' &&
        this.filters.month === 'all' &&
        this.filters.category === 'all' && 
        this.filters.roomId === 'all'
      ) {
        return true;
      }

      //1 variable change
      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.month === this.filters.month
        );
      }
      
      //2 variable change
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.month === this.filters.month
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.roomId === this.filters.roomId
        );
      }

      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.month === this.filters.month &&
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId &&
          expense.category === this.filters.category
        );
      }

      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId &&
          expense.month === this.filters.month
        );
      }

      //3 variable change
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category &&
          expense.month === this.filters.month
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category &&
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.month === this.filters.month &&
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.category === this.filters.category &&
          expense.month === this.filters.month &&
          expense.roomId === this.filters.roomId
        );
      }

      return (
        expense.month === this.filters.month &&
        expense.propertyId === this.filters.propertyId &&
        expense.category === this.filters.category && 
        expense.roomId === this.filters.roomId
      );
    });

    return filteredData;
  }

  get filteredNoRecurring() {
    const filteredData = Object.values(this.dataNoRecurring).filter(expense => {

      if (
        this.filters.propertyId === 'all' &&
        this.filters.month === 'all' &&
        this.filters.category === 'all' && 
        this.filters.roomId === 'all'
      ) {
        return true;
      }

      //1 variable change
      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.month === this.filters.month
        );
      }
      
      //2 variable change
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.month === this.filters.month
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.roomId === this.filters.roomId
        );
      }

      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.month === this.filters.month &&
          expense.category === this.filters.category
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId &&
          expense.category === this.filters.category
        );
      }

      if (this.filters.propertyId === 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.roomId === this.filters.roomId &&
          expense.month === this.filters.month
        );
      }

      //3 variable change
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId === 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category &&
          expense.month === this.filters.month
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category !== 'all'&& this.filters.month === 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.category === this.filters.category &&
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId !== 'all' && this.filters.category === 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.propertyId === this.filters.propertyId &&
          expense.month === this.filters.month &&
          expense.roomId === this.filters.roomId
        );
      }
      if (this.filters.propertyId === 'all' && this.filters.category !== 'all'&& this.filters.month !== 'all' && this.filters.roomId !== 'all') {
        return (
          expense.category === this.filters.category &&
          expense.month === this.filters.month &&
          expense.roomId === this.filters.roomId
        );
      }

      return (
        expense.month === this.filters.month &&
        expense.propertyId === this.filters.propertyId &&
        expense.category === this.filters.category && 
        expense.roomId === this.filters.roomId
      );
    });

    return filteredData;
  }


  setFilters = ({ year = "", month = "", propertyId = "", category = "", roomId = "" }) =>
    (this.filters = { year, month, propertyId, category, roomId });


  *fetchWithoutProperty() {
    try {    
      const response = yield api.get(`/expense/${this.filters.year}`);
      this.data = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchRecurring() {
    try {    
      const response = yield api.get(`/expense/recurring/${this.filters.year}`);
      this.dataRecurring = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
  *fetchNoRecurring() {
    try {    
      const response = yield api.get(`/expense/noRecurring/${this.filters.year}`);
      this.dataNoRecurring = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(expense) {
    try {
      const response = yield api.post(`/expense`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(id, expense) {
    try {
      const response = yield api.patch(`/expense/${id}`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *updateAll(id, expense) {
    try {
      const response = yield api.patch(`/expense/recurring/${id}`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(id) {
    try {
      const response = yield api.delete(`/expense/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteRecurring(id) {
    try {
      const response = yield api.delete(`/expense/deleteReccuring/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
  *stopRecurring(id) {
    try {
      const response = yield api.delete(`/expense/stopReccuring/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
  *sameById(id) {
    try {
      const response = yield api.get(`/expense/sameById/${id}`);
      if (Array.isArray(response.data)) {
        this.setSelectedExpense(response.data);
      } else {
        console.warn("Expected array but received:", response.data);
        this.setSelectedExpense([]);
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
