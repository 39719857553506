
export function updateItems(updatedItem, items) {
  if (!items?.length) {
    return [updatedItem];
  }

  const index = items.findIndex((item) => item._id === updatedItem._id);
  if (index > -1) {
    return [
      ...items.slice(0, index),
      updatedItem,
      ...items.slice(index + 1, items.length),
    ];
  }

  return [...items, updatedItem];
}

export function updateUsers(updatedItem, items) {
  if (Array.isArray(updatedItem)) {
    const updatedItems = items.filter(item => !updatedItem.find(updated => updated._id === item._id));
    return [...updatedItems, ...updatedItem];
  }

  if (!items?.length || !items.find(item => item._id === updatedItem._id)) {
    return [...items, updatedItem];
  }

  return items.map(item => (item._id === updatedItem._id ? updatedItem : item));
}

