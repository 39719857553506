import { computed, flow, makeObservable, observable, toJS } from 'mobx';

import { apiFetcher } from '../utils/fetch';
import moment from 'moment';
import api from '../utils/apiService';

export default class Dashboard {
  constructor() {
    this.data = {};
    makeObservable(this, {
      data: observable,
      fetch: flow,
      currentRevenues: computed,
    });
  }
  get currentRevenues() {
    const currentMonth = moment().format('MMYYYY');
    // Check if this.data.revenues is defined
    if (this.data.revenues) {
      const revenues = toJS(
        this.data.revenues.find(({ month }) => currentMonth === month)
      ) || {
        month: currentMonth,
        paid: 0,
        notPaid: 0,
      };
  
      revenues.notPaid = Math.abs(revenues.notPaid);
      return revenues;
    } else {
      // Handle the case where this.data.revenues is undefined
      console.error("this.data.revenues is undefined");
      return {
        month: currentMonth,
        paid: 0,
        notPaid: 0,
      };
    }
  }
  

  *fetch() {
    try {
      const response = yield api.get('/dashboard');
      this.data = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
