import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';

export function PriceField({ disabled, label, ...props}) {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const { isSubmitting } = useFormikContext();

  return (
    <FormControl margin="normal" fullWidth>
      {label ? (
        <InputLabel htmlFor={props.name} error={hasError} shrink>
          {label}
        </InputLabel>
      ) : null}

      <Input
        startAdornment={<InputAdornment position="start">Rp.</InputAdornment>}
        error={hasError}
        disabled={disabled || isSubmitting}
        fullWidth
        {...props}
        {...field}
        type="number"
      />

      {hasError && (
        <FormHelperText error={hasError}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  )
}