import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { TextField } from './TextField';
import useTranslation from 'next-translate/useTranslation';
import { SelectAddressField } from './SelectAddressField';
import axios from 'axios';
import { NumberField } from './NumberField';

export function AddressField({ disabled, prov, dist, subDist, vill }) {
  const { t } = useTranslation('common');
  const [province, setProvince] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(prov !== 0 ? prov : "");
  const [selectedDistrict, setSelectedDistrict] = useState(dist !== 0 ? dist : "");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(subDist !== 0 ? subDist : "");
  const [selectedVillage, setSelectedVillage] = useState(vill !== 0 ? vill : "");

  const useStyles = makeStyles({
    no_arrow: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  });
  const classes = useStyles();

  // Function to capitalize the first letter of each word and handle extra spaces
  const capitalizeFirstLetter = (string) => 
    string
      .toLowerCase()
      .replace(/\s+/g, ' ') // Remove extra spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word

  const sortedData = (field) => field.sort((a, b) => a.nama.localeCompare(b.nama));

  useEffect(() => {
    // Load province data
    const fetchProvinceData = async () => {
      try {
        const responseProvince = await axios.get("https://ibnux.github.io/data-indonesia/provinsi.json");
        const sortedProvince = sortedData(responseProvince.data);
  
        const formattedProvince = sortedProvince.map(province => {
          let provinceName = capitalizeFirstLetter(province.nama);
  
          // Correct the specific case of "P A P U A"
          if (provinceName === "P A P U A") {
            provinceName = "Papua";
          }
  
          return {
            id: province.id,
            label: provinceName,
            value: `${province.id}-${provinceName}`
          };
        });
        
        setProvince(formattedProvince);
  
        if (prov !== 0) {
          const responseDistrict = await axios.get(`https://ibnux.github.io/data-indonesia/kabupaten/${prov}.json`);
          const responseSubDistrict = await axios.get(`https://ibnux.github.io/data-indonesia/kecamatan/${dist}.json`);
          const responseVillage = await axios.get(`https://ibnux.github.io/data-indonesia/kelurahan/${subDist}.json`);
  
          const formattedDistrict = sortedData(responseDistrict.data).map(district => ({
            id: district.id,
            label: capitalizeFirstLetter(district.nama),
            value: `${district.id}-${capitalizeFirstLetter(district.nama)}`
          }));
  
          const formattedSubDistrict = sortedData(responseSubDistrict.data).map(subDistrict => ({
            id: subDistrict.id,
            label: capitalizeFirstLetter(subDistrict.nama),
            value: `${subDistrict.id}-${capitalizeFirstLetter(subDistrict.nama)}`
          }));
  
          const formattedVillage = sortedData(responseVillage.data).map(village => ({
            id: village.id,
            label: capitalizeFirstLetter(village.nama),
            value: `${village.id}-${capitalizeFirstLetter(village.nama)}`
          }));
  
          setDistricts(formattedDistrict);
          setSubDistricts(formattedSubDistrict);
          setVillages(formattedVillage);
        }
  
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };
    fetchProvinceData();
  }, []);  

  const fetchData = async (url, setter, errorMessage) => {
    try {
      const response = await axios.get(url);
      const sortedData = response.data.sort((a, b) => a.nama.localeCompare(b.nama));
      
      const formattedData = sortedData.map(data => ({
        id: data.id,
        label: capitalizeFirstLetter(data.nama), // Capitalize first letter of each word
        value: `${data.id}-${capitalizeFirstLetter(data.nama)}`
      }));
      setter(formattedData);
    } catch (error) {
      console.error(errorMessage, error);
    }
  };

  // Handle province selection
  const handleProvinceChange = (selectedValue) => {
    setSelectedDistrict("");
    setSelectedSubDistrict("");
    setSelectedVillage("");
    setSubDistricts([]);
    setVillages([]);
    const [provinceCode, provinceName] = selectedValue.target.value.split('-');
    setSelectedProvince(selectedValue.target.value);
    const url = `https://ibnux.github.io/data-indonesia/kabupaten/${provinceCode}.json`;
    fetchData(url, setDistricts, "Error fetching districts:");
  };

  // Handle district selection
  const handleDistrictChange = (selectedValue) => {
    setSelectedSubDistrict("");
    setSelectedVillage("");
    setVillages([]);
    const [districtCode, districtName] = selectedValue.target.value.split('-');
    setSelectedDistrict(selectedValue.target.value);
    const url = `https://ibnux.github.io/data-indonesia/kecamatan/${districtCode}.json`;
    fetchData(url, setSubDistricts, "Error fetching sub-districts:");
  };

  // Handle sub-district selection
  const handleSubDistrictChange = (selectedValue) => {
    setSelectedVillage("");
    const [subDistrictCode, subDistrictName] = selectedValue.target.value.split('-');
    setSelectedSubDistrict(selectedValue.target.value);
    const url = `https://ibnux.github.io/data-indonesia/kelurahan/${subDistrictCode}.json`;
    fetchData(url, setVillages, "Error fetching villages:");
  };

  // Handle village selection
  const handleVillageChange = (selectedValue) => {
    setSelectedVillage(selectedValue.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            label={t('Street 1')+' *'}
            name="address.street1"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberField
            className={classes.no_arrow}
            label={t('Zip code')+' *'}
            name="address.zipCode"
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectAddressField
            label={t('Province')+' *'}
            name="address.province"
            values={province}
            value={selectedProvince}
            onChange={handleProvinceChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectAddressField
            label={t('District')+' *'}
            name="address.district"
            values={districts}
            value={selectedDistrict}
            onChange={handleDistrictChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectAddressField
            label={t('SubDistrict')+' *'}
            name="address.subDistrict"
            values={subDistricts}
            value={selectedSubDistrict}
            onChange={handleSubDistrictChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectAddressField
            label={t('Village')+' *'}
            name="address.village"
            values={villages}
            value={selectedVillage}
            onChange={handleVillageChange}
          />
        </Grid>
      </Grid>
    </>
  );
}
